import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Menu, Tooltip } from "@hexocean/braintrust-ui-components";
import {
  ArrowRightTopIcon,
  HelpOutlineIcon,
  KeyboardArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { handleBookingTalentsCalendarClick } from "@js/apps/jobs/utils";
import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { capitalizeEachFirstLetter } from "@js/utils";

import {
  useBidInterviewIntendedMutation,
  useUpdateEmployerBidSectionMutation,
} from "../../api";
import { useSubmitSchedulingLink } from "../../hooks/use-submit-scheduling-link";
import { openInterviewRequestMessage } from "../bid-messages-modals/interview-request-message";

type InterviewButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  onOptionClick?: () => void;
  closeDrawer?: () => void;
} & Pick<ButtonProps, "sx" | "size" | "className">;

export const InterviewButton = ({
  job,
  bid,
  onOptionClick,
  closeDrawer,
  ...buttonProps
}: InterviewButtonProps) => {
  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();
  const [bidInterviewIntended] = useBidInterviewIntendedMutation();
  const { refetchBidList } = useBidsContext();

  const { submitConfirmedLink } = useSubmitSchedulingLink({
    bid,
    job,
  });

  const disableInterviewOption = !!job.completed_at || job.openings_left === 0;

  const handleSendCalendarClick = () => {
    onOptionClick?.();
    submitConfirmedLink();
  };

  return (
    <Menu
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchor={
        <Tooltip
          title={bid.can_send_interview_request_explanation}
          open={disableInterviewOption ? undefined : false}
        >
          <span>
            <Button
              variant="primary"
              shape="squared"
              endIcon={<KeyboardArrowDownIcon />}
              disabled={disableInterviewOption}
              {...buttonProps}
            >
              Interview
            </Button>
          </span>
        </Tooltip>
      }
    >
      {bid.calendar_url ? (
        <Menu.Item
          onClick={async () => {
            onOptionClick?.();
            await handleBookingTalentsCalendarClick(bid, bidInterviewIntended);
          }}
        >
          <Box display="flex" alignItems="center">
            View {capitalizeEachFirstLetter(bid.freelancer.user.first_name)}’s
            calendar
            <ArrowRightTopIcon sx={{ ml: 0.5, fontSize: "120%" }} />
          </Box>
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => {
            onOptionClick?.();
            openInterviewRequestMessage({ job, refetchBidList, bid });
          }}
        >
          Send interview request
        </Menu.Item>
      )}
      <Menu.Item onClick={handleSendCalendarClick}>Send my calendar</Menu.Item>
      <Menu.Item
        tooltipText="Moves Talent to the “Interviewing” stage. Note this will not send any message to the Talent."
        displayTooltipOnlyIfDisabled={false}
        onClick={async () => {
          onOptionClick?.();
          await updateEmployerBidSection({
            bidId: bid.id,
            status: ENUMS.BidStatus.INTERVIEWING,
          });
          refetchBidList?.();
          if (closeDrawer) closeDrawer();
        }}
      >
        <Box display="flex" alignItems="center">
          Mark “interviewing”
          <HelpOutlineIcon sx={{ ml: 0.5, fontSize: "120%" }} />
        </Box>
      </Menu.Item>
    </Menu>
  );
};
