import { useUser } from "@js/apps/common/hooks/use-user";

export const useFreelancerMyWorkMainPath = () => {
  const user = useUser();
  const canUseFeaturesRelatedToJob =
    user?.freelancer_can_use_job_related_features;

  return {
    myWorkMainPath: `/talent/dashboard/my_jobs${canUseFeaturesRelatedToJob ? "/history/" : "/invites/"}`,
  };
};
